import {_} from 'underscore';
import React, { Component } from 'react';
import SVGLoader from '../utils/svg-loader.utils';
import './modal.component.css';
import loaders from '../utils/loaders.utils';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      randomLoader: _.sample(loaders),
      open: false
    };
  }

  componentDidMount() {
    this.loader = new SVGLoader(document.getElementById('modal'), this.state.randomLoader.options);
    this.loader.show(() => {
      this.setState({open: true});
    });
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (!nextState.open) {
      this.loader.hide(() => {
        nextProps.close();
      });
    }
  }

  close() {
    this.setState({
      open: false,
    });
  }

  render() {
    const { randomLoader } = this.state;

    return (
      <div
        id='modal'
        className='Modal'
        data-opening={randomLoader.dataOpening}
        data-closing={randomLoader.dataClosing}>
        <svg width='100%' height='100%' viewBox='0 0 80 60' preserveAspectRatio={randomLoader.preserveAspectRatio || 'none'}>
          <path d={randomLoader.path} style={{
            fill: '#fff'
          }}/>
        </svg>
        <div className='inner' style={{background: this.state.open ? 'white' : 'transparent'}}>
          <div 
            className='preloader'
            style={{opacity: this.state.open ? 1 : 0}}>
            {this.props.children}
          </div>
          <div className='close' onClick={this.close.bind(this)} style={{
            opacity: this.state.open ? 1 : 0,
          }}>
            <img src="icons/close.svg" alt="close"/>
          </div>
        </div>
      </div>
    );
  }
};

export default Modal;
