import { connect } from 'react-redux';
import React, { Component } from 'react';
import './app.component.css';
import * as Actions from '../actions';
import Canvas from './canvas.component';
import Header from './header.component';
import Loader from './loader.component';
import Modal from './modal.component';
import Particles from './particles.component';

import Projects from './projects.component';
import Profile from './profile.component';
import Contact from './contact.component';

const componentMap = {
  Profile: <Profile />,
  Projects: <Projects />,
  Contact: <Contact />,
};

class App extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.particlesState === 'loaded' &&
      nextProps.particlesJSState === 'loaded' &&
      nextProps.loaderState === 'open') {
      setTimeout(()=> {
        this.props.dispatch(Actions.hideLoader());
      }, 1000);
    }
  }

  showModal = (modal) => {
    this.props.dispatch(Actions.showModal(modal));
  }

  hideModal = () => {
    this.props.dispatch(Actions.hideModal());
  }

  toggleMenu = () => {
    this.props.dispatch(Actions.toggleMenu());
  }

  hideLoader = () => {
    this.props.dispatch(Actions.hideLoader());
  }

  removeLoader = () => {
    this.props.dispatch(Actions.removeLoader());
  }

  setLoaderState = (state) => {
    this.props.dispatch(Actions.setLoaderState(state));
  }

  render() {
    const { 
      dispatch,
      loaderState,
      menuIsOpen,
      modalIsOpen,
      selectedParticle,
    } = this.props;

    return (
      <div className="App">
        {loaderState !== 'starting' ? ([
          <div key='wrapper' className='wrapper'>
            <h1 className='title modified-jello-title'>
              {'Glipcode'}
            </h1>
          </div>,
          <Particles key='particles' />,
          <Canvas key='canvas' />,
          <Header key='header'
            isOpen={menuIsOpen}
            showModal={this.showModal}
            toggleMenu={this.toggleMenu}
          />,
          modalIsOpen ? (
            <Modal 
              key='modal'
              close={this.hideModal}
              isOpen={modalIsOpen}
            >
              {selectedParticle && selectedParticle.component ?
                componentMap[selectedParticle.component]: undefined}
            </Modal>
          ) : undefined,
        ]) : undefined}
        {loaderState !== 'hidden' ? <Loader
          hideLoader={this.hideLoader}
          removeLoader={this.removeLoader}
          setLoaderStateOpen={this.setLoaderState.bind(this, 'open')}
          loaderState={loaderState}
          dispatch={dispatch}
        />: undefined}
      </div>
    );
  }
}

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(App);
