import {_} from 'underscore';
import React, { Component } from 'react';
import SVGLoader from '../utils/svg-loader.utils';
import './loader.component.css';
import loaders from '../utils/loaders.utils';

class Loader extends Component {
  state = {
    randomLoader: _.sample(loaders),
  };

  componentDidMount() {
    this.loader = new SVGLoader(document.getElementById('loader'), this.state.randomLoader.options);
    this.loader.show(() => {
      this.props.setLoaderStateOpen();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { removeLoader } = this.props;
    if (nextProps.loaderState === 'hiding') {
      this.loader.hide(() => {
        removeLoader();
      });
    }
  }

  render() {
    const { randomLoader } = this.state;

    return (
      <div
        id='loader'
        className='Loader'
        data-opening={randomLoader.dataOpening}
        data-closing={randomLoader.dataClosing}>
        <svg width='100%' height='100%' viewBox='0 0 80 60' preserveAspectRatio={randomLoader.preserveAspectRatio || 'none'}>
          <path d={randomLoader.path} style={{
            fill: '#fff'
          }}/>
        </svg>
        <div className='inner'>
          <div 
            className='preloader'
            style={{
              opacity: this.props.loaderState === 'open' ? 1 : 0
            }}>
            <img alt='loading' src='images/preloader.gif'/>
          </div>
        </div>
      </div>
    );
  }
};

export default Loader;
