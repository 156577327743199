import {CSSTransitionGroup} from 'react-transition-group';
import React from 'react';
import bowser from 'bowser';
import './contact.component.css';

const Button = ({background, src, href})=> (
  <a href={href} target='_blank' rel="noopener noreferrer">
    <div className={`button ${bowser.mobile ? 'mobile' : ''}`} style={{
      backgroundImage: `url(${src})`,
    }}>
    </div>
  </a>
);

const Contact = () => (
  <CSSTransitionGroup
    transitionName="bounce"
    transitionAppear={true}
    transitionAppearTimeout={3000}
    transitionEnter={false}
    transitionLeave={false}>
    <div className="Contact">
      <Button src="images/paper-plane.png" href="mailto:simon.glipcode@gmail.com?Subject=Hello!"/>
      <Button src="images/twitter.jpeg" href="https://twitter.com/simonrtucker" />
      <Button src="images/linkedin.png" href="https://www.linkedin.com/in/simonrtucker"/>
      <Button src="images/medium.png" href="https://medium.com/@simontucker" />
    </div>
  </CSSTransitionGroup>
);

export default Contact;