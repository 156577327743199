import {
  HIDE_LOADER,
  SHOW_LOADER,
  REMOVE_LOADER,
  SET_LOADER_STATE,
  HIDE_MODAL,
  SHOW_MODAL,
  TOGGLE_MENU,
  SET_PARTICLESJS_STATE,
  SET_PARTICLES_STATE,
} from './constants';

export const hideLoader = () => ({ type: HIDE_LOADER});
export const showLoader = () => ({ type: SHOW_LOADER});
export const removeLoader = () => ({ type: REMOVE_LOADER});
export const setLoaderState = (state) => ({
  type: SET_LOADER_STATE,
  state,
});

export const hideModal = () => ({ type: HIDE_MODAL});
export const showModal = (particle) => ({
  type: SHOW_MODAL,
  particle
});

export const toggleMenu = () => ({ type: TOGGLE_MENU });

export const setParticlesJSState = (state) => ({
  type: SET_PARTICLESJS_STATE,
  state
});

export const setParticlesState = (state) => ({
  type: SET_PARTICLES_STATE,
  state,
});
