import 'raf/polyfill';
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './components/app.component';
import initReactFastclick from 'react-fastclick';
import './index.css';
import store from './store';
import registerServiceWorker from './registerServiceWorker';

initReactFastclick();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

registerServiceWorker();
