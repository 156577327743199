import _ from 'underscore';
import React, { Component } from 'react';
import bowser from 'bowser';
import {connect} from 'react-redux';

import { particles } from '../content';

import * as Actions from '../actions';

function buildCanvas(component) {
  const {props} = component;

  let canvasWrapper = document.getElementById('particles-simon');
  let canvas = document.querySelector('#particles-simon canvas');  // canvas object
  let link = null;

  let context = canvas.getContext('2d');
  let mouse = {
    clientX: -999,
    clientY: -999
  }; // mouse object

  // particle preset sizes
  let sizes = {
    small: 50,
    large: 200,
    default: 100,
  };

  // particle preset speeds
  let speeds = {
    slow: 2,
    fast: 5,
    default: 3,
  };

  let orientation = window.orientation;
  let isReady = false;
  let pullVelocity = .2;
  let hoverRadius = .5 * canvas.width;  // radius of affect from mousemove
  let bubbleRatio = 0.5;  // onhover particle growth factor

  canvas.width = document.body.clientWidth;
  canvas.height = document.body.clientHeight;

  // track the mouse movement on window
  window.addEventListener('mousemove', function(e) {
    mouse = e;
  });

  // track mouse clicking on canvas
  canvasWrapper.addEventListener('click', function(e) {
    mouse.clicking = true;
  });

  // track mouse clicking on canvas
  canvasWrapper.addEventListener('touchstart', function(e) {
    mouse = e.touches[0];
    mouse.clicking = true;
  });

  // track window resizing
  window.addEventListener('resize', function() {
    canvas.width = document.body.clientWidth;
    canvas.height = document.body.clientHeight;

    if (window.hasOwnProperty('orientation')) {
      if (orientation !== window.orientation) {
        // reset the particles to fit the screen
        orientation = window.orientation;
        _.each(particles, setupParticle);
      }
    } else {
      // reset the particles to fit the screen
      _.each(particles, setupParticle);
    }
  });

  function checkReady() {
    if (_.every(_.pluck(particles, 'ready'))) {
      isReady = true;
      props.dispatch(Actions.setParticlesState('loaded'));
    }
  }

  // initialize the particles and start the step function
  function initialize() {
    step();
    _.each(particles, setupParticle);
  }

  // start the app
  initialize();

  // draw circle cropped img with origin at x, y, and width w
  function drawCroppedImage(x, y, w, a, s, image, grayscale, background) {
    context.save();

    context.beginPath();
    context.arc(x, y, w / 2, 0, Math.PI * 2, true);

    // set a border
    if (s) {
      context.lineWidth = s;
      context.stroke();
    }

    // set a white background and fill by hover range
    if (background) {
      // fill white
      context.globalAlpha = 1;
      context.fillStyle = 'white';
      context.fill();

      // fill specified background color
      context.globalAlpha = Math.max(a, 0);
      context.fillStyle = background;
      context.fill();
    }

    context.closePath();
    context.clip();

    context.globalAlpha = 1;
    context.drawImage(image, x - w / 2, y - w / 2, w, w);
    if (grayscale) {
      context.globalAlpha = 1 - a;
      context.drawImage(grayscale, x - w / 2, y - w / 2, w, w);
    }

    context.restore();
  }

  function setupParticle(particle) {
    // set the width and speed
    particle.width = particle.width || sizes[particle.size] || sizes.default;
    particle.speed = particle.velocity ||
      speeds[particle.speed] || speeds.default;

    // randomize direction
    particle.vX = Math.random() - 0.5;
    particle.vY = Math.random() - 0.5;

    // randomize origin
    particle.posX = Math.random() *
      (canvas.width - particle.width) + particle.width / 2;
    particle.posY = Math.random() *
      (canvas.height - particle.width) + particle.width / 2;

    // load all the images for the particles to start (grayscale if desktop)
    if (!particle.ready) {
      loadImages(particle);
    }
  }

  function step() {
    if (!isReady) {
      checkReady();
      requestAnimationFrame(step);
      return;
    }

    // clear the canvas
    context.clearRect(0, 0, canvas.width, canvas.height);

    link = null;
    // update the coordinates
    _.each(particles, updateParticle);
    if(link) {
      component.setState({
        href: link,
        target: '_blank'
      });
    } else {
      component.setState({
        href: undefined,
        target: undefined,
      });
    }

    // change the cursor if over an item
    if (mouse.particle) {
      canvasWrapper.style.cursor = 'pointer';
    } else {
      canvasWrapper.style.cursor = 'auto';
    }

    if (mouse.clicking && mouse.particle && !props.modalIsOpen) {
      if (mouse.particle.component) {
        props.dispatch(Actions.showModal(mouse.particle));
      } else if (mouse.particle.link) {
        if (bowser.ios) {
          window.location.href = mouse.particle.link;
        } else {
          // window.open(mouse.particle.link, '_blank');
        }
      }

      // reset mouse coordinates
      mouse = {
        clientX: -999,
        clientY: -999
      };
    }

    if (bowser.mobile || bowser.tablet) { // reset mouse coordinates if no hover
      mouse = {
        clientX: -999,
        clientY: -999
      };
    }
    // reset clicking and hovering
    mouse.clicking = false; // maybe will need to remove this after some animation finishes

    requestAnimationFrame(step);
  }

  function updateParticle(particle) {
    if (particle.ready) {
      // update direction
      if (particle.posX >= canvas.width - particle.width / 2 ||
        particle.posX <= particle.width / 2) {
        particle.vX = -particle.vX;
      }
      if (particle.posY >= canvas.height - particle.width / 2 ||
        particle.posY <= particle.width / 2) {
        particle.vY = -particle.vY;
      }

      // get the relative mouse position and hovering zone ratio
      let dX = mouse.clientX - particle.posX;
      let dY = mouse.clientY - particle.posY;
      let dist = Math.sqrt(dX * dX + dY * dY);
      let ratio = 1 - dist / hoverRadius;

      // update size if in hovering zone
      const newWidth = particle.width + (!bowser.mobile && !bowser.tablet) *
        (bubbleRatio * particle.width * ratio);

      // pull the particle if you're in the hovering zone
      if (ratio > 0) {
        let normVec = {x: dX / dist, y: dY / dist};

        particle.posX += normVec.x * ratio * pullVelocity;
        particle.posY += normVec.y * ratio * pullVelocity;
      }

      // update position
      particle.posX += particle.vX * particle.speed;
      particle.posY += particle.vY * particle.speed;

      // check for mouse click
      if (dist <= newWidth / 2) {
        mouse.particle = particle;
        if (particle.link) {
          link = particle.link;
        }
      }

      // draw the cropped image
      drawCroppedImage(
        particle.posX,
        particle.posY,
        Math.max(particle.width, newWidth),
        ratio,
        5,
        particle.image,
        particle.grayscale,
        particle.background
      );
    }
  }
};

function loadImages(particle) {
  let canvas = document.createElement('canvas');
  let context = canvas.getContext('2d');
  particle.image = new Image();

  // grayscale if desktop
  if (!bowser.mobile && !bowser.tablet) {
    particle.grayscale = new Image();

    // ready the particle for display
    particle.grayscale.onload = function() {
      particle.ready = true;
    };
  }

  // once you load the image, you can create a grayscale image
  particle.image.onload = function() {
    if (!bowser.mobile && !bowser.tablet) {
      canvas.width = particle.image.width;
      canvas.height = particle.image.height;
      context.drawImage(particle.image, 0, 0);

      let imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      let px = imageData.data;

      for (let i = 0; i < px.length; i += 4) {
        let gray = px[i] * .3 + px[i + 1] * .59 + px[i + 2] * .11;
        px[i] = px[i + 1] = px[i + 2] = gray;
      }

      context.putImageData(imageData, 0, 0);
      particle.grayscale.src = canvas.toDataURL();
    } else {
      particle.ready = true;
    }
  };

  // set the source of the color image
  particle.image.src = particle.src;
}

const styles = {
  css: {
    left: 0,
    top: 0,
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  canvas: {
    css: {
      height: '100%',
      width: '100%'
    }
  }
};

class Canvas extends Component {
  state = {};

  componentDidMount() {
    buildCanvas(this);
  }

  render() {
    return (
      <div id='particles-simon' style={styles.css}>
        <a href={this.state.href} target={this.state.target}>
          <canvas style={styles.canvas.css}></canvas>
        </a>
      </div>
    );
  }
};

export default connect()(Canvas);
