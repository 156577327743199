import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'remote-redux-devtools';

import reducer from './reducers';

const store = createStore(reducer, {loaderState: 'starting'}, composeWithDevTools(
  applyMiddleware(thunk),
  // other store enhancers if any
));

export default store;