export const GET_MEDIUM = 'GET_MEDIUM';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const HIDE_LOADER = 'HIDE_LOADER';
export const SHOW_LOADER = 'SHOW_LOADER';
export const REMOVE_LOADER = 'REMOVE_LOADER';
export const SET_LOADER_STATE = 'SET_LOADER_STATE';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_MODAL = 'SHOW_MODAL';
export const TOGGLE_MENU = 'TOGGLE_MENU'
export const SET_PARTICLESJS_STATE = 'SET_PARTICLESJS_STATE';
export const SET_PARTICLES_STATE = 'SET_PARTICLES_STATE';
