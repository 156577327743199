import {CSSTransitionGroup} from 'react-transition-group';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import './profile.component.css';

const styles = {
  title: {
    css: {
      textAlign: 'center',
    },
    image: {
      css: {
        margin: 'auto',
      }
    },
    header: {
      css: {
        marginBottom: 0,
      }
    },
    subheader: {
      css: {
        marginTop: 0,
      }
    },
    location: {
      css: {
        paddingTop: '10px'
      }
    }
  },
}

const ProfileButton = ({position, style, src, description}) => (
  <div className='profile-container' style={position}>
    <div className='profile' style={Object.assign({
      backgroundImage: `url(${src})`,
    }, style)}>
    </div>
    <div className='description'>{description}</div>
  </div>
);

const buttons = [
  <ProfileButton
    key='0'
    style={{width: '9vw', height: '9vw'}}
    position={{right: '50vw', top: '10vh'}}
    src={`${process.env.PUBLIC_URL}/images/chef.jpg`}
    description='chef'
  />,
  <ProfileButton
    key='1'
    style={{width: '17vw', height: '17vw'}}
    position={{right: '40vw', top: '30vh'}}
    src='/images/developer.jpg'
    description='developer'
  />,
  <ProfileButton
    key='2'
    style={{width: '12vw', height: '12vw'}}
    position={{right: '2vw', top: '20vh'}}
    src={`${process.env.PUBLIC_URL}/images/explorer.jpg`}
    description='explorer'
  />,
  <ProfileButton
    key='3'
    style={{width: '13vw', height: '13vw',}}
    position={{left: '13vw', bottom: '13vh',}}
    src={`${process.env.PUBLIC_URL}/images/uncle.jpg`}
    description='uncle'
  />,
  <ProfileButton
    key='4'
    style={{width: '15vw', height: '15vw',}}
    position={{left: '15vw', top: '15vh',}}
    src={`${process.env.PUBLIC_URL}/images/san-franciscan.jpg`}
    description='san franciscan'
  />,
  <ProfileButton
    key='5'
    style={{width: '15vw', height: '15vw',}}
    position={{right: '30vw', bottom: '15vh',}}
    src={`${process.env.PUBLIC_URL}/images/snowboarder.jpg`}
    description='snowboarder'
  />,
  <ProfileButton
    key='6'
    style={{width: '10vw', height: '10vw',}}
    position={{right: '10vw', bottom: '30vh',}}
    src={`${process.env.PUBLIC_URL}/images/musician.jpg`}
    description='musician'
  />,
  <ProfileButton
    key='7'
    style={{width: '18vw', height: '18vw',}}
    position={{right: '20vw', top: '10vh',}}
    src={`${process.env.PUBLIC_URL}/images/husband.jpg`}
    description='husband'
  />,
  <ProfileButton
    key='8'
    style={{width: '15vw', height: '15vw',}}
    position={{left: '30vw', bottom: '20vh',}}
    src={`${process.env.PUBLIC_URL}/images/dad.jpg`}
    description='dad'
  />,
];

class Profile extends Component {
  state = {
    counter: -1,
  }

  componentDidMount() {
    const _this = this;

    let counter = 0;

    buttons.map(button => {
      const image = new Image();
      image.src = button.props.src;
      image.onload = ()=> {
        counter++;
        if(counter === buttons.length) {
          _this.setState({loaded: true});
        }
      };
      return image;
    });
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const _this = this;

    if(nextState.loaded && !this.state.loaded) {
      this.interval = setInterval(()=> {
        if (_this.state.counter === buttons.length - 1) {
          clearInterval(_this.interval);
          _this.interval = null;
        }
        _this.setState({ counter: _this.state.counter + 1 });
      }, 300);
    }
  }

  componentWillUnmount() {
    if(this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  render() {
    const {loaded} = this.state;

    if(!loaded) {
      return (
        <img alt='loading' src='images/preloader.gif'/>
      );
    }

    return (
      <div className='Profile' style={styles.title.css}>
        <CSSTransitionGroup
          transitionName="bounce"
          transitionEnter={true}
          transitionEnterTimeout={0}
          transitionLeave={false}>
          {this.state.counter > -1 && buttons.slice(0, this.state.counter)}
        </CSSTransitionGroup>
      </div>
    )
  }
}

export default connect()(Profile);
