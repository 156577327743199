import { connect } from 'react-redux';
import React, { Component } from 'react';
import particlesJS from '../utils/particles.utils';
import * as Actions from '../actions';

const styles = {
  css: {
    height: '100%',
    left: 0,
    top: 0,
    position: 'absolute',
    width: '100%'
  },
};

class Particles extends Component {
  state = {
    loaded: false
  };

  componentDidMount() {
    const { props } = this;
    particlesJS.load('particles-js', 'particles.json', ()=> {
      props.dispatch(Actions.setParticlesJSState('loaded'));
    });
  }

  render() {
    return (
      <div id='particles-js' style={styles.css}></div>
    );
  }
}

export default connect()(Particles);
