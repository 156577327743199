import {CSSTransitionGroup} from 'react-transition-group';
import React, { Component } from 'react';
import classNames from 'classnames';
import './header.component.css';
import './transformicon.css';
import { particles } from '../content';

// bubble links below menu items
const HeaderLink = ({ title, src, link }) => (
  <a
    href={link}
    target='_blank'
    className='HeaderLink'
    rel="noopener noreferrer"
  >
    <div
      className='icon'
    >
      <img alt={title} src={src} style={{width: '100%'}}/>
    </div>
  </a>
);

// corner header menu
class Header extends Component {
  render() {
    const {isOpen, showModal, toggleMenu} = this.props;
    const buttonClass = classNames({
      'button': true,
      'tcon': true,
      'tcon-menu--xcross': true,
      'tcon-transform': isOpen,
    });

    const menuClass = classNames({
      menu: true,
      row: true,
      animated: true,
    });

    let Menu;

    if (isOpen) {
      Menu = (
        <div className={menuClass} key='menu'>
          {particles
            .filter((particle)=> particle.component)
            .map((item)=> (
              <div
                className='item'
                key={item.title}
                onClick={showModal.bind(this, item)}>
                {item.title}
              </div>
          ))}
          <div>
            {particles
              .filter((particle)=> !particle.component)
              .map((link)=> (
                <HeaderLink
                  key={link.title + '-header-link'}
                  link={link.link}
                  src={link.src}
                  title={link.title}
                />
              ))}
          </div>
        </div>
      );
    }

    return (
      <div id='header' className='Header'>
        <div className='row' style={{height: '50px'}}>
          <button
            key='button'
            type='button'
            className={buttonClass}
            onClick={toggleMenu}>
            <span className='tcon-menu__lines'/>
            <span className='tcon-visuallyhidden'>toggle menu</span>
          </button>
        </div>
        <CSSTransitionGroup
          transitionName="modified-jello"
          transitionEnter={true}
          transitionLeave={true}
          transitionEnterTimeout={0}
          transitionLeaveTimeout={0}>
          {Menu}
        </CSSTransitionGroup>
      </div>
    );
  }
};

export default Header;
