import {
  HIDE_LOADER,
  REMOVE_LOADER,
  SHOW_LOADER,
  SET_LOADER_STATE,
  HIDE_MODAL,
  SHOW_MODAL,
  TOGGLE_MENU,
  SET_PARTICLESJS_STATE,
  SET_PARTICLES_STATE,
} from './constants';

const main = (state = {}, action = {}) => {
  switch (action.type) {
    case HIDE_MODAL:
      return Object.assign({}, state, {modalIsOpen: false});
    case HIDE_LOADER:
      return Object.assign({}, state, {loaderState: 'hiding'});
    case REMOVE_LOADER:
      return Object.assign({}, state, {loaderState: 'hidden'});
    case SET_LOADER_STATE:
      return Object.assign({}, state, {loaderState: action.state});
    case SHOW_LOADER:
      return Object.assign({}, state, {loaderState: 'opening'});
    case SHOW_MODAL:
      return Object.assign({}, state, {
        selectedParticle: action.particle,
        modalIsOpen: true,
        menuIsOpen: false
      });
    case TOGGLE_MENU:
      return Object.assign({}, state, {
        menuIsOpen: !state.modalIsOpen && !state.menuIsOpen
      });
    case SET_PARTICLESJS_STATE:
      return Object.assign({}, state, {
        particlesJSState: action.state
      });
    case SET_PARTICLES_STATE:
      return Object.assign({}, state, {
        particlesState: action.state
      });
    default:
      return state;
  }
}

export default main;