// init the particles with custom props
export const particles = [
  {
    component: 'Profile',
    size: 'large',
    speed: 'slow',
    src: 'images/profile.jpg',
    title: 'about me',
  },{
    component: 'Contact',
    speed: 'slow',
    src: 'images/mail-icon.png',
    title: 'contact',
  },{
    component: 'Projects',
    background: 'white',
    speed: 'slow',
    src: 'images/glipchat.png',
    title: 'projects',
  },{
    link: 'https://github.com/srtucker22',
    size: 'small',
    speed: 'slow',
    src: 'images/octobiwan.jpg',
    title: 'github',
  },{
    link: 'https://www.linkedin.com/in/simonrtucker',
    size: 'small',
    speed: 'slow',
    src: 'images/linkedin.png',
    title: 'linkedin',
  },{
    background: 'white',
    link: 'https://twitter.com/simonrtucker',
    size: 'small',
    speed: 'slow',
    src: 'images/twitter.jpeg',
    title: 'twitter',
  },
];

export const projects = [{
  title: 'Google Train Up',
  link: 'https://trainup.withgoogle.com',
  images: {
    desktop: [
      'images/trainup/imac.png',
    ],
    mobile: [
      'images/trainup/iphone.png',
    ],
    tablet: [
      'images/trainup/ipad.png',
    ],
  },
  blurb: 'a rewarding way for sales professionals to learn and train',
},{
  title: 'chatty',
  link: 'https://medium.com/@simontucker/building-chatty-a-whatsapp-clone-with-react-native-and-apollo-part-1-setup-68a02f7e11',
  images: {
    mobile: ['images/chatty/iphone.png']
  },
  blurb: 'build the next WhatsApp',
},{
  title: 'Sessionwire',
  link: 'https://sessionwire.com',
  images: {
    desktop: [
      'images/sessionwire/imac.png',
    ],
  },
  blurb: 'live connections for music makers',
},{
  title: 'magic video player',
  images: {
    mobile: ['images/andy/iphone.png']
  },
  blurb: 'stream inline video ads on any mobile browser',
},{
  title: 'glipchat',
  link: 'https://glipchat.herokuapp.com/',
  images: {
    desktop: ['images/glipchat/imac.png']
  },
  blurb: 'open source video chatroom for any device',
},{
  title: 'cryptobot',
  link: 'https://glacial-cove-67914.herokuapp.com/',
  images: {
    mobile: ['images/cryptobot/iphone.png']
  },
  blurb: 'friendly cryptogram solving robot',
}];

export default particles;
